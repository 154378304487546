class Lightbox {
    element = $('.js-lightbox');
    items: any;
  constructor() {
    this.items = this.element.find('.js-item');
    let openItems;
    this.items.each((index, item) => {
        let items = $(item);
        let openItems = new LightboxItems(items);
    });

  }
}


class LightboxItems {
    element: any;
    lightbox = $('.c-lightbox');
    src: any;
    type: any;
    img: any;
    videoElement: any;
    closeBtn: any;
    nextBtn: any;
    prevBtn: any;

    currentImg: any;
    nextImg: any;
    prevImg: any;

    constructor(items) {
        this.element = items;
        this.currentImg = this.element;
        this.nextImg = this.currentImg.next();
        this.prevImg = this.currentImg.prev();


        if(!this.lightbox.length) return;
        this.element.find('.js-lightbox__inner').on('click', this.open.bind(this));
        this.src = this.element.find('.js-lightbox__inner').attr('href');
        this.type = this.element.attr('data-type');
        this.img = `<div class='c-lightbox__imgwrapper'>
                        <img unselectable="on" class='c-lightbox__image' src='${this.src}' />
                        <button class='c-lightbox__close c-btn mt-0 c-btn--no-icon  c-btn--square'>
                            <span class='u-accessibility'>Close</span>
                            <span class="material-symbols-outlined">
                              close
                            </span>
                        </button>
                        <button class='c-btn c-lightbox__prev mt-0 c-btn--no-icon  c-btn--square'>
                						<span class='u-accessibility'>back</span>
                						<span class="material-symbols-outlined">
                							navigate_before
                						</span>
                				</button>
                				<button class='c-btn c-lightbox__next mt-0 c-btn--no-icon  c-btn--square'>
                						<span class='u-accessibility'>next</span>
                						<span class="material-symbols-outlined">
                							navigate_next
                						</span>
                				</button>
                    </div>`;
        this.videoElement = `<div class='u-video c-lightbox__video'>
                                <iframe width='560' height='315' src='${this.src}' frameborder='0' allow='autoplay; encrypted-media' allowfullscreen></iframe>
                                <button class='c-lightbox__close c-btn mt-0 c-btn--no-icon  c-btn--square'>
                                    <span class='u-accessibility'>Close</span>
                                    <span class="material-symbols-outlined">
                                      close
                                    </span>
                                </button>
                            </div>`;
    }
    open(event) {
        event.preventDefault();
        let galleryLength = this.element.parent().find("li").length;
        let index = this.element.index();
        this.lightbox.addClass('u-is-visible');

        $(".c-lightbox__image").attr('src', this.element.find('.js-lightbox__inner').attr('href'));
        if (this.type == 'video') {
            this.lightbox.find('.c-lightbox__inner').append(this.videoElement);
        }else if (this.type == 'image') {
            this.lightbox.find('.c-lightbox__inner').append(this.img);
        }

        this.closeBtn = this.lightbox.find('.c-lightbox__close');
        this.closeBtn.on('click', this.close.bind(this));
        this.nextBtn = this.lightbox.find('.c-lightbox__next');
        this.prevBtn = this.lightbox.find('.c-lightbox__prev');

          this.nextBtn.on('click', function() {
            if(index < galleryLength) {
              index++;
            }
            if (index == galleryLength - 1) {
                 this.nextBtn.hide();
            }
            if (index > 0) {
                  this.prevBtn.show();
            }
            let link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
          }.bind(this));

          $(".c-lightbox__image").on('click', function() {
            console.log('text')
            if(index < galleryLength) {
              index++;
            }
            if (index == galleryLength - 1) {
                 this.nextBtn.hide();
            }
            if (index > 0) {
                  this.prevBtn.show();
            }
            let link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
          }.bind(this));

        if(!this.element.prev().length) {
          this.prevBtn.hide();
        }
        if(!this.element.next().length) {
          this.nextBtn.hide();
        }
        this.prevBtn.on('click', function() {
          if(index == 1) {
            this.prevBtn.hide();
          }
          if(index < galleryLength ) {
            this.nextBtn.show();
          }
          index--;
            let link = this.element.parent().find("li").eq(index).find("a");
            $(".c-lightbox__image").attr('src', link.attr('href'));
        }.bind(this));

    }

    close() {
        this.lightbox.removeClass('u-is-visible');
        $('.c-lightbox__inner').empty();
    }

}

let lichtbox = new Lightbox();
