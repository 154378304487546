class Popup {
  element: any;
  close: any;
  popup: any;
  cookiename: any;
    constructor() {
      this.element = $(".js-popup");
      let cookiename = getCookie("verdelpopup");
      if (cookiename != "closed") {
        if ($(window).width() >= 600) {
            window.setTimeout(function(){
            $(".js-popup").addClass("js-popup--show");
          }, 5000);
        }
      }
      this.close = this.element.find(".js-close");
      if(!this.element.length) return;
      this.close.on('click', function() {
        this.element.removeClass("js-popup--show");
        document.cookie = "verdelpopup=closed; expires=0; path=/; SameSite=None; secure";
      }.bind(this));

    }
}
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if(c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}
let popup = new Popup();
