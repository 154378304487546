class ValidateField {
    validatefield: any;
    constructor() {
      $("#umbraco_form_beea6d1008714471be1bf2a380c82666").find("form").on("submit", function(event) {
        console.log();
        if($('input[name=991d2f93-b060-420c-811b-24861836206a]:checked').length == 0) {
          var errorText = $('input[name=991d2f93-b060-420c-811b-24861836206a]')[0].getAttribute("data-msg-required");
          $(".js-validate").append(errorText)
          $(".js-validate").show();
        }else {
          $(".js-validate").hide();
        }
      });
    }
}
let validateField = new ValidateField();
